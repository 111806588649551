import { Body } from "./Body/Body";
import { Header } from "./Header/Header";
import { GenericColumnModel, FilterSortPageType, FilterType, InteractionManager } from "./models";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import WithRefetch from "features/Fetch/WithRefetch";
import ErrorBoundary from "features/ErrorBoundary";
import styles from './customTable.module.scss'
import { Frozen } from "./Separators/Frozen";
import { Pagination } from "../BaseTable/Pagination/Pagination";
import { Resize } from "./Separators/Resize";
import { Filter } from "./Filter/Filter";
import { SkeletonTable } from "./SkeletonTable/SkeletonTable";
import { columnMinWidth, defaultPaginationLimit } from "./consts";
import { getColumnMaxInitialWidth } from "./heplers";

type Props = {
	columns: GenericColumnModel[]
	rowsData: any[]
	fetchFunction: () => Promise<any>
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	isLoading?: boolean

	pagination?: {
		offset?: number // starting index
		limit?: number // max number of rows
		count: number // length of all items
		onChange: (offset: number) => void
	}
	compact?: boolean

	filterSortPage: FilterSortPageType
	disabledReorder: boolean

	/**
	 * - fill: Table will resize the columns and expand each column equally to fill up width of the table. However, if column has set width, it will keep that specific width.
	 * - If fill is not set, fitData will be used by default. Table will resize the columns to fit their data, and ensure that rows take up the full width of the table.
	*/
	fill?: boolean

	interactionManager: InteractionManager
}

export type ColumnWidthMap = { [columnId: string]: number }

export const CustomTable = ({
	columns, rowsData, fetchFunction, cellEdited,
	pagination, compact, filterSortPage, disabledReorder, fill, interactionManager
}: Props) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [columnWidthMap, setColumnWidthMap] = useState<ColumnWidthMap>({});
	const [isCalculatedColumnWidths, setIsCalculatedColumnWidths] = useState(false);

	const [activeFilterColumn, setActiveFilterColumn] = useState<GenericColumnModel>();

	const calculateColumnWidthsCallback = useCallback(
		() => {
			const container = containerRef.current;

			if (!container) {
				return;
			}

			const maxColumnWidthMap: ColumnWidthMap = {};

			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				const columnId = column.id;

				// if width is set, use it directly
				if (column.width) {
					maxColumnWidthMap[columnId] = column.width;
				} else {
					// if width is not set, get all the cells that belong to this column and calculate which cell width is the biggest
					const innerCells = container.querySelectorAll(`[data-columnid="${columnId}"]`);
					const maxInitialWidth = getColumnMaxInitialWidth(column);

					maxColumnWidthMap[columnId] = columnMinWidth;

					for (let j = 0; j < innerCells.length; j++) {
						// calculate cellWidth as content width + cell horizontal padding
						const innerCell = innerCells[j];
						const cell = innerCell.closest('[data-type="cell"]')!;
						const paddingLeft = window.getComputedStyle(cell, null).getPropertyValue('padding-left');
						const paddingRight = window.getComputedStyle(cell, null).getPropertyValue('padding-right');
						let cellWidth = innerCell.getBoundingClientRect().width + parseInt(paddingLeft) + parseInt(paddingRight);

						// if cell is editable it contains small icon that is shown on hover, so we leave space for icon
						if (column.editable) {
							cellWidth += 18; // TODO: 18 is change icon width + margin-left, and it shouldn't be hardcoded here
						}

						// if cellWidth is bigger than maxInitialWidth, then calculation is finished and we use maxInitialWidth value as Column width
						if (cellWidth >= maxInitialWidth) {
							maxColumnWidthMap[columnId] = maxInitialWidth;
							break;
						}

						if (cellWidth > maxColumnWidthMap[columnId]) {
							maxColumnWidthMap[columnId] = Math.ceil(cellWidth);
						}
					}
				}
			}

			const tableContainerWidth = container.getBoundingClientRect().width;
			const totalColumnWidth = columns.reduce((total, column) => total + maxColumnWidthMap[column.id], 0);

			// if fill === true and there is more empty space on the right, increase column widths so there is no empty space
			if (fill && totalColumnWidth < tableContainerWidth) {
				const extraSpace = tableContainerWidth - totalColumnWidth;
				// increase column widths equally, except ones that have width set (GenericColumnModel.width)
				const affectedColumns = columns.filter((column) => !column.width);
				for (const column of affectedColumns) {
					const columnId = column.id;
					maxColumnWidthMap[columnId] = maxColumnWidthMap[columnId] + Math.round(extraSpace / affectedColumns.length);
				}
			}

			setColumnWidthMap(maxColumnWidthMap);
			setIsCalculatedColumnWidths(true);
		},
		[columns, fill]
	)

	useLayoutEffect(
		() => {
			// added rowsData just to trigger calculation on data change
			if (rowsData.length) {
				calculateColumnWidthsCallback();
			}
		},
		[columns, rowsData, calculateColumnWidthsCallback]
	)

	const setFiltersCallback = useCallback(
		(newFilters: FilterType[]) => {
			interactionManager.filter?.(newFilters);
			setActiveFilterColumn(undefined);
		},
		[interactionManager]
	)

	const cancelFilterCallback = useCallback(
		() => setActiveFilterColumn(undefined),
		[]
	)

	const onFilterClickCallback = useCallback(
		(columnId: string) => {
			const selectedColumn = columns.find((column) => column.id === columnId);
			setActiveFilterColumn(selectedColumn);
		},
		[columns]
	)

	const handleRowClickCallback = useCallback(
		(rowData: any) => interactionManager.click?.(rowData),
		[interactionManager]
	)

	return (
		<ErrorBoundary location='CustomTable'>
			<WithRefetch fetchFunction={fetchFunction} withBackground={isCalculatedColumnWidths}>
				{!isCalculatedColumnWidths && <SkeletonTable />}
				<div>
					{isCalculatedColumnWidths &&
						<Filter
							filters={filterSortPage.filters || []}
							column={activeFilterColumn}
							columns={columns}
							onSave={setFiltersCallback}
							onCancel={cancelFilterCallback}
						/>
					}
					<div ref={containerRef} className={styles.container} style={{ visibility: isCalculatedColumnWidths ? 'visible' : 'hidden' }}>
						<Header
							columns={columns}
							columnWidthMap={columnWidthMap}
							filterSortPage={filterSortPage}
							onFilter={onFilterClickCallback}
							disabledReorder={disabledReorder}
							interactionManager={interactionManager}
						/>
						<Body
							columns={columns}
							rowsData={rowsData}
							columnWidthMap={columnWidthMap}
							onRowClick={handleRowClickCallback}
							cellEdited={cellEdited}
							interactionManager={interactionManager}
						/>
						<Resize
							columns={columns}
							columnWidthMap={columnWidthMap}
							setColumnWidthMap={setColumnWidthMap}
							interactionManager={interactionManager}
						/>
						<Frozen
							columns={columns}
							columnWidthMap={columnWidthMap}
							containerRef={containerRef}
						/>
					</div>
					{pagination && isCalculatedColumnWidths &&
						<Pagination
							offset={pagination.offset || 0}
							limit={pagination.limit || defaultPaginationLimit}
							count={pagination.count}
							onChange={pagination.onChange}
							compact={compact}
						/>
					}
				</div>
			</WithRefetch>
		</ErrorBoundary>
	)
}
