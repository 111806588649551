import { useEffect, useState } from 'react'
import styles from './skeletonTable.module.scss'

const rows = 4;

export const SkeletonTable = () => {
	const [columns, setColumns] = useState([3, 1, 2, 1, 3]);

	useEffect(
		() => {
			const width = window.innerWidth;
			if (width < 1280) {
				setColumns((state) => state.slice(0, -1));
			}
		},
		[]
	)

	const renderCells = (columns: number[]) =>
		columns.map((col, colIndex) => <div key={colIndex} className={`${styles.cell} ${styles[`flex_${col}`]}`} />
	)

	return (
		<div className={styles.skeleton}>
			<div className={styles.header}>
				{renderCells(columns)}
			</div>
			{[...Array(rows)].map((_, rowIndex) => (
				<div key={rowIndex} className={styles.row}>
					{renderCells(columns)}
				</div>
			))}
		</div>
	)
}
