import { useCallback, useMemo, useState } from "react"
import { GenericColumnModel, InteractionManager } from "../models"
import styles from './body.module.scss'
import { Row } from "./Row"
import { ColumnWidthMap } from "../CustomTable"

type Props = {
	columns: GenericColumnModel[]
	rowsData: any[]
	columnWidthMap: ColumnWidthMap
	onRowClick: (row: any) => void
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	interactionManager: InteractionManager
}

export const Body = ({ columns, rowsData, columnWidthMap, onRowClick, cellEdited, interactionManager }: Props) => {
	const [selectedRowIndex, setSelectedRowIndex] = useState<number>();

	const handleRowClickCallback = useCallback(
		(rowData: any, rowIndex: number) => {
			setSelectedRowIndex((state) => state !== rowIndex ? rowIndex : undefined);
			onRowClick(rowData);
		},
		[onRowClick]
	)

	const rowsContent = useMemo(
		() => rowsData.map((row, rowIndex) => (
			<Row
				key={`row_${rowIndex}`}
				columns={columns}
				rowData={row}
				onClick={() => handleRowClickCallback(row, rowIndex)}
				interactionManager={interactionManager}
				isSelected={rowIndex === selectedRowIndex}
				columnWidthMap={columnWidthMap}
				cellEdited={cellEdited}
			/>
		)),
		[columns, rowsData, handleRowClickCallback, interactionManager, cellEdited, selectedRowIndex, columnWidthMap]
	)

	return (
		<div className={styles.container}>
			{rowsContent}
		</div>
	)
}
