import ConfigureView from './ConfigureView';
import { configureViewReducer, ConfigureViewType as ReducerType } from './reducer';
import { setConfigureViewTableAction, ConfigureViewActionType as ActionType } from './action';

export {
	configureViewReducer,
	setConfigureViewTableAction
};

export type ConfigureViewActionType = ActionType;
export type ConfigureViewType = ReducerType

export default ConfigureView;
