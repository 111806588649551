import { DatePicker } from 'components/Form'

type Props = {
	value: Date
	onChange(value: Date): void
	onBlur(): void
}

export const DateEditableCell = ({ value, onChange, onBlur }: Props) => {
	return (
		<DatePicker
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			size='small'
			focus
		/>
	)
}
