import { Input } from "components/Form"

type Props = {
	value: string
	onChange(value: string): void
	onBlur(): void
}

export const StringEditableCell = ({ value, onChange, onBlur }: Props) => {
	return (
		<Input
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			hideMaxLength
			size='small'
			focus
		/>
	)
}
