import { useCallback, useState, memo, useMemo, useRef, useEffect } from 'react'
import { GenericColumnModel, BooleanColumnModel, CurrencyColumnModel, FormatterCell, DateColumnModel, FormattedReferenceColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, StringColumnModel } from '../models'
import styles from './body.module.scss'
import { EditableCell } from './EditableCell/EditableCell'
import { getFormatedId } from 'utils/commonHelper'
import { formatDate } from 'utils/dateTimeUtils'
import { useFormattedCurrencyValueCallback, useOrganizationalUnitCallback } from 'features/TableColumns/persistedHooks'
import { CheckIcon, ChangeArrowsIcon } from 'components/icons/icons'
import { Tooltip } from '../Tooltip/Tooltip'

type Props = {
	column: GenericColumnModel
	value: any
	columnWidth: number
	rowData: any
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
}

const CellWithoutMemo = ({ column, value, columnWidth, rowData, cellEdited }: Props) => {
	const contentRef = useRef<HTMLDivElement>(null);
	const [isEditActive, setIsEditActive] = useState(false);
	const [saving, setSaving] = useState(false);
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });

	const getFormattedCurrencyValue = useFormattedCurrencyValueCallback();
	const getOrganizationalUnit = useOrganizationalUnitCallback();

	useEffect(
		() => {
			const content = contentRef.current;
			if (content) {
				setIsEllipsisActive(content.scrollWidth > content.clientWidth);
			}
		},
		[value, columnWidth]
	)

	const formattedOptionValue = useCallback(
		(cellVelue: any) => {
			const { items, getItemId, getItemText, getItems } = column as OptionColumnModel<any>;
			const newItems = getItems ? getItems(cellVelue) : items;
			if (newItems && getItemId && getItemText) {
				const selectedOption = newItems.find((item: any) => getItemId(item) === cellVelue);
				return selectedOption ? getItemText(selectedOption) : cellVelue;
			}
			return cellVelue;
		},
		[column]
	)

	const formatterCellDataCallback = useCallback(
		() => {
			if (column.formatter) {
				const cell: FormatterCell = {
					value,
					rowData
				}

				return column.formatter(cell);
			}

			if (column instanceof FormattedReferenceColumnModel) {
				return getFormatedId(column.entityPrefix, parseInt(value));
			} else if (column instanceof DateColumnModel) {
				return formatDate(value);
			} else if (column instanceof CurrencyColumnModel) {
				return getFormattedCurrencyValue(parseInt(value));
			} else if (column instanceof OptionsColumnModel || column instanceof OptionColumnModel) {
				return formattedOptionValue(value);
			} else if (column instanceof MapOptionColumnModel) {
				return getOrganizationalUnit(parseInt(value), column.items);
			} else if (column instanceof BooleanColumnModel) {
				return value ? <CheckIcon width={20} height={20} fill='currentColor' /> : <></>;
			} else if (column instanceof NumberColumnModel) {
				return value ? parseInt(value) : '';
			} else if (column instanceof StringColumnModel) {
				return value ? value.toString() : '';
			} else {
				return value ? value.toString() : '';
			}
		},
		[value, rowData, column, getFormattedCurrencyValue, formattedOptionValue, getOrganizationalUnit]
	)

	const clickCallback = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!column.editable || saving) {
				return;
			}

			// don't select row
			e.stopPropagation();
			setIsEditActive(true);
		},
		[column.editable, saving]
	)

	const onChangeCompleteCallback = useCallback(
		async (columnId: string, value: any) => {
			if (cellEdited && rowData[columnId] !== value) {
				setSaving(true);
				await cellEdited(rowData, columnId, value);
				setSaving(false);
			}

			setIsEditActive(false);
		},
		[rowData, cellEdited]
	)

	const styleMemo = useMemo(
		() => !isEditActive && column.getStyle ? column.getStyle(value) : {},
		[value, isEditActive, column]
	)

	const showTooltipCallback = useCallback(
		(e: React.MouseEvent) => {
			if (!isEllipsisActive) {
				return;
			}

			setPosition({ x: e.clientX + 12, y: e.clientY + 12 });
			setIsTooltipVisible(true);
		},
		[isEllipsisActive]
	)

	const hideTooltipCallback = useCallback(
		() => setIsTooltipVisible(false),
		[]
	)

	const className = `${styles.cell} ${column.editable && isEditActive ? styles.editing : ''} ${!!column.frozen ? styles.frozen : ''}`;

	return (
		<div className={className} style={{ width: `${columnWidth}px`, ...styleMemo }} onClick={clickCallback} data-type='cell'>
			{isEditActive ?
				<EditableCell
					column={column}
					value={value}
					onChangeComplete={onChangeCompleteCallback}
					loading={saving}
				/>
				:
				<div ref={contentRef} className={styles.content} onMouseOver={showTooltipCallback} onMouseLeave={hideTooltipCallback}>
					<span className={styles.inner} data-columnid={column.id}>
						{formatterCellDataCallback()}
					</span>
					{isEllipsisActive && isTooltipVisible &&
						<Tooltip
							content={formatterCellDataCallback().toString()}
							topPosition={position.y}
							leftPosition={position.x}
						/>
					}
					{column.editable && <ChangeArrowsIcon className={styles.hover_icon} width={12} height={12} fill='#fff' title={`Change ${column.title.toLowerCase()}`} />}
				</div>
			}
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
