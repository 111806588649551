import { useState, useCallback, useEffect, } from 'react';
import { Dialog } from 'components/Dialog';
import { RowContainer } from 'components/Layout';
import Button from 'components/Button';
import { MultiList, MultiListType } from 'components/DragNDrop';
import notifications from 'components/Notification/notification';
import ColumnContainer from 'components/Layout/ColumnContainer';
import { getTableColumnsDataByMultiList, getMultiListDataByTableColumns } from 'features/ConfigureView/multiListHelper';
import { setConfigureViewTableAction } from './action';

export type ConfigureViewColumnType = {
	field: string
	title: string
	visible?: boolean
}

type Props = {
	id: string
	tableColumns: ConfigureViewColumnType[]
}

const ConfigureView = ({ id, tableColumns }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentMultiList, setCurrentMultiList] = useState<MultiListType>(
		getMultiListDataByTableColumns(tableColumns)
	);
	const [loading, setLoading] = useState(false);

	useEffect(
		() => {
			setCurrentMultiList(getMultiListDataByTableColumns(tableColumns));
		},
		[tableColumns]
	)

	const openModalCallback = useCallback(
		() => {
			setIsModalOpen(true)
		},
		[]
	);

	const closeModalCallback = useCallback(
		() => setIsModalOpen(false),
		[]
	);

	const saveCallback = useCallback(
		async () => {
			setLoading(true);
			const newTableColumns = getTableColumnsDataByMultiList(
				currentMultiList,
				tableColumns
			);
			const visibleColumns = newTableColumns.filter(column => column.visible);
			const columnFields: string[] = visibleColumns.map(column => column.field)
			const response = await setConfigureViewTableAction(id, columnFields);
			if (response.success) {
				notifications.success('View configuration is updated.');
			}
			setLoading(false);
			setIsModalOpen(false);
		},
		[id, currentMultiList, tableColumns]
	)

	return (
		<>
			<Button
				onClick={openModalCallback}
				color='neutral'
				text='Configure View'
			/>
			<Dialog
				title='Configure view'
				onClose={closeModalCallback}
				open={isModalOpen}
			>
				<ColumnContainer margin='medium'>
					<MultiList
						onDragEnd={setCurrentMultiList}
						initialMultiList={currentMultiList}
					/>
					<RowContainer justifyContent='flex-end'>
						<Button
							onClick={saveCallback}
							text='Save'
							isLoading={loading}
							disabled={currentMultiList.columnMap['selected'].elementIds.length === 0}
						/>
						<Button
							onClick={closeModalCallback}
							color='neutral'
							text='Cancel'
							disabled={loading}
						/>
					</RowContainer>
				</ColumnContainer>
			</Dialog>
		</>
	)
}

export default ConfigureView
