import { SemaphoreItemType } from "components/Form/controls/Semaphore/SemaphoreItem";
import { EntityPrefixEnum } from "utils/commonHelper"

// TODO: rename all types after implemented

/** Type of first argument in Column formatter method */
export type FormatterCell = {
	/** Unformatted value of the cell */
	value: any;
	/** GenericColumnModel concrete object that is passed to the row that contains the cell */
	rowData: any;
}

/** Abstract model of Column. Concrete models extend this one. */
export class GenericColumnModel {
	/** Column id */
	id: string;
	/** Title that is displayed in Column header */
	title: string;
	/** Returns formatted value for display. Table has its fallback formatters based on type of Column, so in most common cases it is not needed to set it. */
	formatter?: (cell: FormatterCell) => string | number;
	/** Initial width of column. User can resize that column. */
	width?: number;
	/** Column that needs to be frozen/sticky to the start/end of the table, and not scrolled horizontaly.
	 * For now 'start' value should be set only for first n columns, and 'end' for last m columns (we cannot say 'start' or 'end' for column that is somewhere in the middle) */
	frozen?: 'start' | 'end';
	/** Vertical aling of the Cell content */
	align?: 'left' | 'center' | 'right';
	/** Cells inside of this Column are clickable and editable control based on the type of Column will be mounted inside of the Cell */
	editable?: boolean;
	/** If cell needs to have specific CSS styles, this method should be used and return object that is passed to style property of Cell component. For example color of Cell content. */
	getStyle?: (value: any) => React.CSSProperties;
	/** Removes sort icon from Column header. */
	disableSort?: boolean;
	/** Removes filter functionality from Column header. */
	disableFilter?: boolean;

	constructor(data: GenericColumnModel) {
		this.id = data.id;
		this.title = data.title;
		this.formatter = data.formatter;
		this.width = data.width;
		this.frozen = data.frozen;
		this.align = data.align;
		this.editable = data.editable;
		this.getStyle = data.getStyle;
		this.disableSort = data.disableSort;
		this.disableFilter = data.disableFilter;
	}
}

export class StringColumnModel extends GenericColumnModel { }
export class DateColumnModel extends GenericColumnModel { }
export class NumberColumnModel extends GenericColumnModel { }
export class BooleanColumnModel extends GenericColumnModel { }
export class CurrencyColumnModel extends GenericColumnModel { }

export class SemaphoreColumnModel extends GenericColumnModel {
	colors: SemaphoreItemType[];

	constructor(data: SemaphoreColumnModel) {
		super(data);
		this.colors = data.colors;
	}
}

export class FormattedReferenceColumnModel extends GenericColumnModel {
	entityPrefix: EntityPrefixEnum;

	constructor(data: FormattedReferenceColumnModel) {
		super(data);
		this.entityPrefix = data.entityPrefix;
	}
}

// created generic model, so Option, Options and Map can extend it
// (not good if concrete model extends another concrete model, then we would need to take care about order of if/else checks for instanceof)
class OptionGenericColumnModel<T> extends GenericColumnModel {
	items: T[];
	getItems?: (statusId: number) => T[];
	getItemId: (option: T) => number | string;
	getItemText: (option: T) => string;

	constructor(data: OptionGenericColumnModel<T>) {
		super(data);
		this.items = data.items;
		this.getItems = data.getItems;
		this.getItemId = data.getItemId;
		this.getItemText = data.getItemText;
	}
}

export class OptionsColumnModel<T> extends OptionGenericColumnModel<T> { }
export class OptionColumnModel<T> extends OptionGenericColumnModel<T> { }
export class MapOptionColumnModel<T> extends OptionGenericColumnModel<T> { }

// interaction types

export type SortType = {
	columnId: string
	isAsc: boolean
}

export type OrFilterType = {
	columnId?: string | undefined
	value?: any | undefined
	operation?: any // TODO: GenericFilterOperationEnum
}

export type FilterType = {
	columnId?: string | undefined
	value?: any | undefined
	operation?: any // TODO: GenericFilterOperationEnum
	orFilters?: OrFilterType[] | undefined
}

export type FilterSortPageType = {
	offset: number
	limit: number
	filters?: FilterType[]
	sort?: SortType
}

export type InteractionManager = {
	click?: (rowData: any) => void
	doubleClick?: (rowData: any) => void
	middleClick?: (rowData: any) => void
	reorderColumn?: (columnsOrder: string[]) => Promise<void>
	resizeColumn?: (columnId: string, size: number | undefined) => Promise<void>
	sort?: (sort: SortType) => void
	filter?: (filters: FilterType[]) => void
	changeOffset?: (offset: number) => void
}
