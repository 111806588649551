import Button from 'components/Button';
import { AutoCompleteField, Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterType, OptionsColumnModel } from 'components/Table';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	column: OptionsColumnModel<any>
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

type StateType = {
	selectedValue?: string | number
}

export const OptionFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<StateType>({})

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.id);
			if (!currentFilter) {
				setValues({});
			} else if (currentFilter?.columnId === column.id) {
				setValues({ selectedValue: currentFilter.value })
			}
		},
		[filters, column.id]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.id);
			let newFilter: FilterType = {
				columnId: column.id,
				operation: GenericFilterOperationEnum.EQUALS,
				value: values.selectedValue
			}
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}
			if (newFilter) {
				newFilters.push(newFilter);
			}
			onSave && await onSave(newFilters);
		},
		[column.id, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({});
		},
		[]
	)

	const { items, getItemId, getItemText } = column;

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='Apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<StateType>('selectedValue')}
							label='Select option'
							items={items}
							getItemId={getItemId}
							getItemText={getItemText}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='Clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
