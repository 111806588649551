import { memo, useCallback, useState } from 'react'
import styles from './header.module.scss'
import { FilterIcon, LockIcon } from 'components/icons/icons'
import { SortType } from '../models';

const sortElementId = 'header_cell_sort';

type Props = {
	// from column
	id: string // column id
	title: string
	disableSort?: boolean

	// additional props
	sort?: SortType
	setSort?: (sort: SortType) => void
	hasFilter: boolean
	isFilterActive: boolean
	onFilter(columnId: string): void
	columnWidth: number
	frozen?: boolean
}

const CellWithoutMemo = ({ id, title, disableSort, sort, setSort, hasFilter, isFilterActive, onFilter, columnWidth, frozen }: Props) => {
	const [hover, setHover] = useState(false);

	let arrowClassName = '';

	if (sort?.columnId === id) {
		arrowClassName = `${styles[sort.isAsc ? 'ascending' : 'descending']} ${styles.active_sort}`;
	}

	const mouseOverCallback = useCallback(
		(e: any) => {
			if (e.target.id !== sortElementId && hasFilter) {
				setHover(true);
			}
		},
		[hasFilter]
	)

	const mouseOutCallback = useCallback(
		() => setHover(false),
		[]
	)

	const onSortCallback = useCallback(
		(e: any) => {
			e.stopPropagation();
			setSort?.({
				columnId: id,
				isAsc: sort?.columnId === id ? !sort.isAsc : true
			});
		},
		[sort, setSort, id]
	)

	const onFilterClickCallback = useCallback(
		() => {
			hasFilter && onFilter(id);
		},
		[id, hasFilter, onFilter]
	)

	const className = `${styles.cell} ${hover ? styles.hover : ''} ${frozen ? styles.frozen : ''}`;

	return (
		<div
			className={className}
			data-type='cell'
			style={{ width: `${columnWidth}px` }}
			onMouseOver={mouseOverCallback}
			onMouseOut={mouseOutCallback}
			onClick={onFilterClickCallback}
		>
			<div className={styles.inner} data-columnid={id}>
				{frozen &&
					<LockIcon width={12} height={12} fill={'currentColor'} style={{ marginRight: '6px' }} />
				}
				{isFilterActive &&
					<div className={styles.filter_wrapper}>
						<FilterIcon className={styles.filter} width={14} height={14} stroke='currentColor' />
					</div>
				}
				<span className={styles.title}>{title}</span>
				{!disableSort &&
					<div id={sortElementId} className={`${styles.sort} ${arrowClassName}`} onClick={onSortCallback} />
				}
			</div>
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
