import { useCallback, useMemo, useState } from "react"
import { GenericColumnModel, BooleanColumnModel, DateColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, StringColumnModel } from "../../models"
import { StringEditableCell } from "./StringEditableCell"
import { OptionEditableCell } from "./OptionEditableCell"
import { DateEditableCell } from "./DateEditableCell"
import { NumberEditableCell } from "./NumberEditableCell"
import { BooleanEditableCell } from "./BooleanEditableCell"
import { OptionsEditableCell } from "./OptionsEditableCell"

type Props = {
	column: GenericColumnModel
	value: any
	onChangeComplete(columnId: string, value: any): void
	loading: boolean
}

export const EditableCell = ({ column, value, onChangeComplete, loading }: Props) => {
	const [currentValue, setCurrentValue] = useState(value);

	const onBlurCallback = useCallback(
		() => {
			onChangeComplete(column.id, currentValue);
		},
		[column, currentValue, onChangeComplete]
	)

	const props = useMemo(
		() => {
			return {
				value: currentValue,
				onChange: setCurrentValue,
				onBlur: onBlurCallback,
				loading
			}
		},
		[currentValue, onBlurCallback, loading]
	)

	if (column instanceof OptionColumnModel) {
		return <OptionEditableCell column={column} {...props} />
	} else if (column instanceof OptionsColumnModel) {
		return <OptionsEditableCell column={column} {...props} />
	} else if (column instanceof DateColumnModel) {
		return <DateEditableCell {...props} />
	} else if (column instanceof NumberColumnModel) {
		return <NumberEditableCell {...props} />
	} else if (column instanceof BooleanColumnModel) {
		return <BooleanEditableCell {...props} />
	} else if (column instanceof StringColumnModel) {
		return <StringEditableCell {...props} />
	} else {
		return <StringEditableCell {...props} />
	}
}
